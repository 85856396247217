export const endpoints = {
  adminTask: {
    listTicketing: 'ticketing/list',
    detailTicket: 'ticketing/details',
    updateTicket: 'ticketing-queue/update-status',
    createStatus: 'v1/ticket/create',
    uploadReceipt: 'adminpg/image-public-url',
    listTask: 'adminpg/list-task',
    detailTask: 'adminpg/detail-task',
    addIncident: 'adminpg/create-task',
    editIncident: 'adminpg/edit-task/incident',
    editTask: 'adminpg/edit-task/',
  },
  registeredMerchant: {
    newMerchant: 'adminpg/list-new-merchant',
    prodMerchant: 'adminpg/list-prod-merchant',
    sandboxMerchant: 'adminpg/list-sandbox-merchant',
    detailNewMerchant: 'adminpg/get-merchant',
    approveSandbox: 'adminpg/approve-sandbox-merchant',
    approveProduction: 'adminpg/approve-real-merchant',
    editDocument: 'adminpg/edit-doc-merchant',
    rejectSandbox: 'adminpg/reject-sandbox-merchant',
    rejectProduction: 'adminpg/reject-real-merchant',
    merchantQRIS: 'adminpg/qris-registration-list',
    qrisDetail: 'adminpg/qris-registration-detail',
    editQRIS: 'adminpg/qris-registration-update',
    mbc: 'merchantqris/master-biz-criteria',
    mcc: 'merchantqris/master-mcc',
  },
  report: {
    transaction: 'admin/transaction',
    withDrawalList: 'admin/withdrawals',
    withDrawalParam: 'admin/withdrawal/params',
    mutation: 'merchantpg/mutation',
  },
  merchantPreview: {
    typeCategory: 'merchantpg/bussiness_type_category',
    payments: 'v2/payment',
  },
  dashboard: {
    totalIncome: 'dashboard/total_income',
    totalTransaction: 'dashboard/total_transaction',
    top10Frequency: 'dashboard/top10_frequency',
    top10Nominal: 'dashboard/top10_nominal',
    totalPChannel: 'dashboard/total_payment_channel',
    totalPMethode: 'dashboard/total_payment_method',
    totalAmount: 'dashboard/total_transaksi_amount',
    totalFrequency: 'dashboard/total_transaksi_frequency',
  },
  masterData: {
    list: 'v2/master_data_get_fields',
    detail: 'v2/list_master_data',
    crud: 'v2/table_master_data',
    relationship: 'v2/table_master_data_relationship',
    uploadImgae: 'adminpg/image-master-data-url',
  },
  FMS: {
    dashboard: {
      totalIndicatedMerchant: 'fms/pg_total_indicated',
      totalIndicatedTransaction: 'fms/pg_total_transaction',
      totalIndicatedUsers: 'fms/pg_total_user',
      indicatedCategroyMerchant: 'fms/pg_indicated_mrc_cat',
      indicatedCategroyTransaction: 'fms/pg_indicated_trx_cat',
      indicatedCategroyUser: 'fms/pg_indicated_user_cat',
      trendTotalindicatedMerchant: 'fms/pg_indicated_mrc_date',
      trendTotalindicatedTransaction: 'fms/pg_indicated_trx_date',
      trendTotalindicatedUser: 'fms/pg_indicated_user_date',
      detailIndicated: 'fms/pg_detail_indicated_mrc',
      listMerchantSus: 'fms/pg_mrc_suspend',
    },
    payout: {
      indicatedMerchantType: 'fms/payout_indicated_mrc_type',
      amountMerchantType: 'fms/payout_amount_mrc_type',
      ticketMerchantType: 'fms/payout_ticket_mrc_type',
      indicatedMerchantCategory: 'fms/payout_indicated_mrc_category',
      ticketMerchantCategory: 'fms/payout_ticket_indicated_mrc_cat',
      amountMerchantCategory: 'fms/payout_amount_indicated_mrc_cat',
      indicatedMerchantDate: 'fms/payout_indicated_mrc_date',
      suspendList: 'fms/payout_list_merchant_suspend',
      fraudList: 'fms/payout_indicated_fraud',
    },
    threshold: {
      list: 'fms/threshold_list',
      post: 'fms/threshold',
      put: 'fms/threshold',
      detail: 'fms/threshold_detail',
    },
    fraud: {
      list: 'fms/teroris_list',
      post: 'fms/teroris',
      bulk_teroris: 'fms/teroris_bulk',
      detail: 'fms/teroris_detail/',
      delete: 'fms/teroris/',
      mrcList: 'fms/monitoring_list',
      mrcDetail: 'fms/monitoring/',
      put: 'fms/teroris/',
    },
  },
  login: 'login',
  initialForgotPassword: 'forgot/password',
  validateForgotPassword: 'forgot/password/validation',
  validateEmail: 'email/validation',
  profile: 'clientpg/profile',
  profilePhoto: 'clientpg/profile-photo',
  changePassword: 'password',
  refresh: 'token/refresh',
  refreshImage: 'merchantpg/get_image_url',
  captcha: 'clientpg/captcha',
  notification: {
    List: 'message/list',
    Read: 'message/edit',
    Delete: 'message/delete',
    Detail: 'message/detail',
    categoryMessage: 'message/categories',
  },
  permissionList: 'v2/permission/list',
};
