/* eslint-disable import/no-anonymous-default-export */
import update from 'immutability-helper';
import { ITicketing, ITicketingState, ITicketingTypes } from 'redux/types/ticketingTypes';

const initialState: ITicketingState = {
  isLoading: false,
  error: null,
  ListTicket: {
    Code: 0,
    Message: '',
    Data: [],
    Paginate: {
      current_page: 1,
      total_page: 0,
      total_row: 0,
    },
  },
  DetailTicket: {},
  Detail: {},
};

export default (state = initialState, { type, payload }: ITicketing) => {
  switch (type) {
    case ITicketingTypes.GET_TICKET_PENDING:
      return update(state, { isLoading: { $set: true } });
    case ITicketingTypes.GET_IMAGE_PENDING:
      return update(state, { isLoading: { $set: true } });
    case ITicketingTypes.EXPORT_PENDING:
      return update(state, { isLoading: { $set: true } });
    case ITicketingTypes.CHECK_ACCOUNT_START:
      return update(state, { isLoading: { $set: true } });

    case ITicketingTypes.GET_LIST_TICKETING:
      return update(state, { isLoading: { $set: false }, ListTicket: { $set: payload } });
    case ITicketingTypes.GET_DETAIL_TICKET:
      return update(state, { isLoading: { $set: false }, DetailTicket: { $set: payload } });
    case ITicketingTypes.GET_DETAIL_BEFORE:
      return update(state, { isLoading: { $set: false }, Detail: { before: { $set: payload } } });
    case ITicketingTypes.GET_DETAIL_AFTER:
      return update(state, { isLoading: { $set: false }, Detail: { after: { $set: payload } } });
    case ITicketingTypes.GET_IMAGE_BEFORE:
      return update(state, { isLoading: { $set: false }, Detail: { before: { $merge: payload } } });
    case ITicketingTypes.GET_IMAGE_AFTER:
      return update(state, { isLoading: { $set: false }, Detail: { after: { $merge: payload } } });
    case ITicketingTypes.EXPORT_SUCCESS:
      return update(state, { isLoading: { $set: false }, ListTicket: { Data: { $set: payload } } });

    case ITicketingTypes.GET_TICKET_fAILED:
      return initialState;
    case ITicketingTypes.GET_IMAGE_FAILED:
      return initialState;
    case ITicketingTypes.EXPORT_FAILED:
      return initialState;
    case ITicketingTypes.CHECK_ACCOUNT_FAILED:
      return initialState;
    default:
      return state;
  }
};
