import * as Sentry from '@sentry/react';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { rootReducer } from 'redux/reducer';
import { applyMiddleware, createStore, Middleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import { IAuthState } from 'redux/types/authTypes';
import { IUtilsState } from 'redux/types/utilsTypes';
import { IReportState } from 'redux/types/reportTypes';
import { IRegisteredMerchantState } from 'redux/types/registeredTypes';
import { ISettlementState } from 'redux/types/settlementTypes';
import { IMercPreviewState } from 'redux/types/merchantPreviewTypes';
import { ITicketingState } from 'redux/types/ticketingTypes';
import { IDashboardState } from 'redux/types/dashboardTypes';
import { AdminTaskState } from 'redux/types/adminTasksTypes';
import { IMasterDataState } from 'redux/types/masterDataTypes';
import { INotificationState } from 'redux/types/notificationTypes';
import { IFmsState } from 'redux/types/fmsTypes';
import { IPayoutFmsState } from 'redux/types/fmsPayoutTypes';
import { IThresholdState } from 'redux/types/fmsThresholdTypes';
import { IMerchantFraudState } from 'redux/types/merchantFraudTypes';

let middleware: Middleware[] = [thunk];
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, logger, thunk];
} else {
  middleware = [...middleware, thunk];
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'fms'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middleware), sentryReduxEnhancer));
const persistor = persistStore(store);

export interface RootState {
  auth: IAuthState;
  utils: IUtilsState;
  report: IReportState;
  registered: IRegisteredMerchantState;
  settlement: ISettlementState;
  mercPreview: IMercPreviewState;
  ticketing: ITicketingState;
  dashboard: IDashboardState;
  adminTask: AdminTaskState;
  masterData: IMasterDataState;
  notification: INotificationState;
  fms: IFmsState;
  FmsPayout: IPayoutFmsState;
  Threshold: IThresholdState;
  MrcFraud: IMerchantFraudState;
}

export { store, persistor };
