export enum MerchantFraudType {
  GET_LIST_TERORIS_START = 'GET_LIST_TERORIS_START',
  GET_LIST_TERORIS_SUCCESS = 'GET_LIST_TERORIS_SUCCESS',
  GET_LIST_TERORIS_FAILED = 'GET_LIST_TERORIS_FAILED',
  POST_TERORIS_START = 'POST_TERORIS_START',
  POST_TERORIS_SUCCESS = 'POST_TERORIS_SUCCESS',
  POST_TERORIS_FAILED = 'POST_TERORIS_FAILED',
  GET_DETAIL_TERORIS_START = 'GET_DETAIL_TERORIS_START',
  GET_DETAIL_TERORIS_SUCCESS = 'GET_DETAIL_TERORIS_SUCCESS',
  GET_DETAIL_TERORIS_FAILED = 'GET_DETAIL_TERORIS_FAILED',
  DELETE_TERORIS_START = 'DELETE_TERORIS_START',
  DELETE_TERORIS_SUCCESS = 'DELETE_TERORIS_SUCCESS',
  DELETE_TERORIS_FAILED = 'DELETE_TERORIS_FAILED',
  GET_LIST_MERCHANT_FRAUD_START = 'GET_LIST_MERCHANT_FRAUD_START',
  GET_LIST_MERCHANT_FRAUD_SUCCESS = 'GET_LIST_MERCHANT_FRAUD_SUCCESS',
  GET_LIST_MERCHANT_FRAUD_FAILED = 'GET_LIST_MERCHANT_FRAUD_FAILED',
  GET_DETAIL_MERCHANT_FRAUD_START = 'GET_DETAIL_MERCHANT_FRAUD_START',
  GET_DETAIL_MERCHANT_FRAUD_SUCCESS = 'GET_DETAIL_MERCHANT_FRAUD_SUCCESS',
  GET_DETAIL_MERCHANT_FRAUD_FAILED = 'GET_DETAIL_MERCHANT_FRAUD_FAILED',
  PUT_TERORIS_START = 'PUT_TERORIS_START',
  PUT_TERORIS_SUCCESS = 'PUT_TERORIS_SUCCESS',
  PUT_TERORIS_FAILED = 'PUT_TERORIS_FAILED',
}

export interface IMerchantFraudState {
  TeroristListType: InterfaceTeroristListTypes;
  TeroristPostType: InterfaceTeroristCreateTypes;
  TeroristDetailType: InterfaceTeroristDetailTypes;
  TeroristDeleteType: InterfaceTerorisDeleteTypes;
  TeroristPutType: InterfaceTeroristPutTypes;
  MerchantFraudListType: InterfaceMerchantFraudListTypes;
  MerchantFraudDetailType: InterfaceMerchantFraudDetailTypes;
}

export interface InterfaceTeroristListTypes {
  isLoading: boolean;
  data: ApiResponseTeorisList;
  message: string;
}

export interface InterfaceTeroristCreateTypes {
  isLoading: boolean;
  data: ApiResponseTerorisCreate;
  message: string;
}

export interface InterfaceTeroristPutTypes {
  isLoading: boolean;
  data: ApiResponseTerorisPut;
  message: string;
}

export interface InterfaceTeroristDetailTypes {
  isLoading: boolean;
  data: ApiResponseTerorisDetail;
  message: string;
}

export interface InterfaceTerorisDeleteTypes {
  isLoading: boolean;
  data: ApiResponseTerorisDelete;
  message: string;
}

export interface InterfaceMerchantFraudListTypes {
  isLoading: boolean;
  data: ApiResponseMerchantFraudList[];
  message: string;
}

export interface InterfaceMerchantFraudDetailTypes {
  isLoading: boolean;
  data: ApiResponseMerchantFraudList[];
  message: string;
}

export interface ApiResponseTerorisCreate {
  id?: string;
}

export interface ApiResponseTerorisDelete {
  id?: string;
}

export interface ApiResponseTerorisPut {
  id?: string;
}

export interface ApiResponseTeorisList extends Pagination {
  data: ApiResponseDataTerorisList[];
}

export interface ApiResponseDataTerorisList {
  Id?: string;
  Name?: string;
  Alias?: string;
  NIK?: string;
  City?: string;
  Subdistrict?: string;
  Village?: string;
  PostalCode?: string;
  Address?: string;
  Penerbit?: string;
  Photo?: string;
  Status?: string;
  CreatedDate?: string;
  CreatedBy?: string;
  UpdatedDate?: string;
  UpdatedBy?: string;
}

export interface ApiResponseTerorisDetail {
  Id?: string;
  Name?: string;
  Alias?: string;
  NIK?: string;
  City?: string;
  Subdistrict?: string;
  Village?: string;
  PostalCode?: string;
  Address?: string;
  Penerbit?: string;
  Photo?: string;
  Status?: string;
  CreatedDate?: string;
  CreatedBy?: string;
  UpdatedDate?: string;
  UpdatedBy?: string;
  Uuid?: string;
}

export interface ApiResponseMerchantFraudList {
  MerchantCode?: string;
  MerchantName?: string;
  Status?: string;
  ProcessDate?: string;
  CreatedDate?: string;
  CreatedBy?: string;
  UpdatedDate?: string;
  UpdatedBy?: string;
}

export interface Pagination {
  limit: number;
  next_page: number;
  current_page: number;
  prev_page: number;
  total_page: number;
  total_record: number;
}

interface IGetListTerorisStart {
  type: MerchantFraudType.GET_LIST_TERORIS_START;
  payload: null;
}

interface IGetListTerorisSuccess {
  type: MerchantFraudType.GET_LIST_TERORIS_SUCCESS;
  payload: IMerchantFraudState;
}

interface IGetListTerorisFailed {
  type: MerchantFraudType.GET_LIST_TERORIS_FAILED;
  payload: string;
}

interface IPostTerorisStart {
  type: MerchantFraudType.POST_TERORIS_START;
  payload: null;
}

interface IPostTerorisSuccess {
  type: MerchantFraudType.POST_TERORIS_SUCCESS;
  payload: IMerchantFraudState;
}

interface IPostTerorisFailed {
  type: MerchantFraudType.POST_TERORIS_FAILED;
  payload: string;
}

interface IDetailTerorisStart {
  type: MerchantFraudType.GET_DETAIL_TERORIS_START;
  payload: null;
}

interface IDetailTerorisSuccess {
  type: MerchantFraudType.GET_DETAIL_TERORIS_SUCCESS;
  payload: IMerchantFraudState;
}

interface IDetailTerorisFailed {
  type: MerchantFraudType.GET_DETAIL_TERORIS_FAILED;
  payload: string;
}

interface IDeleteTerorisStart {
  type: MerchantFraudType.DELETE_TERORIS_START;
  payload: null;
}

interface IDeleteTerorisSuccess {
  type: MerchantFraudType.DELETE_TERORIS_SUCCESS;
  payload: IMerchantFraudState;
}

interface IDeleteTerorisFailed {
  type: MerchantFraudType.DELETE_TERORIS_FAILED;
  payload: string;
}

interface IPutTerorisStart {
  type: MerchantFraudType.PUT_TERORIS_START;
  payload: null;
}

interface IPutTerorisSuccess {
  type: MerchantFraudType.PUT_TERORIS_SUCCESS;
  payload: IMerchantFraudState;
}

interface IPutTerorisFailed {
  type: MerchantFraudType.PUT_TERORIS_FAILED;
  payload: string;
}

interface IGetListMerchantFraudStart {
  type: MerchantFraudType.GET_LIST_MERCHANT_FRAUD_START;
  payload: null;
}

interface IGetListMerchantFraudSuccess {
  type: MerchantFraudType.GET_LIST_MERCHANT_FRAUD_SUCCESS;
  payload: IMerchantFraudState;
}

interface IGetListMerchantFraudFailed {
  type: MerchantFraudType.GET_LIST_MERCHANT_FRAUD_FAILED;
  payload: string;
}

interface IGetDetailMerchantFraudStart {
  type: MerchantFraudType.GET_DETAIL_MERCHANT_FRAUD_START;
  payload: null;
}

interface IGetDetailMerchantFraudSuccess {
  type: MerchantFraudType.GET_DETAIL_MERCHANT_FRAUD_SUCCESS;
  payload: IMerchantFraudState;
}

interface IGetDetailMerchantFraudFailed {
  type: MerchantFraudType.GET_DETAIL_MERCHANT_FRAUD_FAILED;
  payload: string;
}

type IListMerchantFraudType =
  | IGetListTerorisStart
  | IGetListTerorisSuccess
  | IGetListTerorisFailed
  | IPostTerorisStart
  | IPostTerorisSuccess
  | IPostTerorisFailed
  | IDetailTerorisStart
  | IDetailTerorisSuccess
  | IDetailTerorisFailed
  | IDeleteTerorisStart
  | IDeleteTerorisSuccess
  | IDeleteTerorisFailed
  | IPutTerorisStart
  | IPutTerorisSuccess
  | IPutTerorisFailed
  | IGetListMerchantFraudStart
  | IGetListMerchantFraudSuccess
  | IGetListMerchantFraudFailed
  | IGetDetailMerchantFraudStart
  | IGetDetailMerchantFraudSuccess
  | IGetDetailMerchantFraudFailed;

export type IMerchantFraud = IListMerchantFraudType;
