import update from 'immutability-helper';
import { IMerchantFraud, IMerchantFraudState, MerchantFraudType } from 'redux/types/merchantFraudTypes';

const initialState = {
  TeroristListType: {
    isLoading: false,
    data: {
      data: [],
      limit: 0,
      current_page: 0,
      next_page: 0,
      prev_page: 0,
      total_page: 0,
      total_record: 0,
    },
    message: '',
  },
  TeroristPostType: {
    isLoading: false,
    data: {},
    message: '',
  },
  TeroristDetailType: {
    isLoading: false,
    data: {},
    message: '',
  },
  TeroristDeleteType: {
    isLoading: false,
    data: {},
    message: '',
  },
  TeroristPutType: {
    isLoading: false,
    data: {},
    message: '',
  },
  MerchantFraudListType: {
    isLoading: false,
    data: [],
    message: '',
  },
  MerchantFraudDetailType: {
    isLoading: false,
    data: [],
    message: '',
  },
};

export default (state: IMerchantFraudState = initialState, { type, payload }: IMerchantFraud) => {
  switch (type) {
    case MerchantFraudType.GET_LIST_TERORIS_START:
      return update(state, {
        TeroristListType: {
          isLoading: { $set: true },
          message: { $set: '' },
        },
      });
    case MerchantFraudType.GET_LIST_TERORIS_SUCCESS:
      return update(state, {
        TeroristListType: {
          isLoading: { $set: false },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case MerchantFraudType.GET_LIST_TERORIS_FAILED:
      return update(state, {
        TeroristListType: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case MerchantFraudType.POST_TERORIS_START:
      return update(state, {
        TeroristPostType: {
          isLoading: { $set: true },
          message: { $set: '' },
        },
      });
    case MerchantFraudType.POST_TERORIS_SUCCESS:
      return update(state, {
        TeroristPostType: {
          isLoading: { $set: false },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case MerchantFraudType.POST_TERORIS_FAILED:
      return update(state, {
        TeroristPostType: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case MerchantFraudType.GET_DETAIL_TERORIS_START:
      return update(state, {
        TeroristDetailType: {
          isLoading: { $set: true },
          message: { $set: '' },
        },
      });
    case MerchantFraudType.GET_DETAIL_TERORIS_SUCCESS:
      return update(state, {
        TeroristDetailType: {
          isLoading: { $set: false },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case MerchantFraudType.GET_DETAIL_TERORIS_FAILED:
      return update(state, {
        TeroristDetailType: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case MerchantFraudType.DELETE_TERORIS_START:
      return update(state, {
        TeroristDeleteType: {
          isLoading: { $set: true },
          message: { $set: '' },
        },
      });
    case MerchantFraudType.DELETE_TERORIS_SUCCESS:
      return update(state, {
        TeroristDeleteType: {
          isLoading: { $set: false },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case MerchantFraudType.DELETE_TERORIS_FAILED:
      return update(state, {
        TeroristDeleteType: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case MerchantFraudType.PUT_TERORIS_START:
      return update(state, {
        TeroristListType: {
          isLoading: { $set: true },
          message: { $set: '' },
        },
      });
    case MerchantFraudType.PUT_TERORIS_SUCCESS:
      return update(state, {
        TeroristListType: {
          isLoading: { $set: false },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case MerchantFraudType.PUT_TERORIS_FAILED:
      return update(state, {
        TeroristListType: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    default:
    case MerchantFraudType.GET_LIST_MERCHANT_FRAUD_START:
      return update(state, {
        MerchantFraudListType: {
          isLoading: { $set: true },
          message: { $set: '' },
        },
      });
    case MerchantFraudType.GET_LIST_MERCHANT_FRAUD_SUCCESS:
      return update(state, {
        MerchantFraudListType: {
          isLoading: { $set: false },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case MerchantFraudType.GET_LIST_MERCHANT_FRAUD_FAILED:
      return update(state, {
        MerchantFraudListType: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case MerchantFraudType.GET_DETAIL_MERCHANT_FRAUD_START:
      return update(state, {
        MerchantFraudDetailType: {
          isLoading: { $set: true },
          message: { $set: '' },
        },
      });
    case MerchantFraudType.GET_DETAIL_MERCHANT_FRAUD_SUCCESS:
      return update(state, {
        MerchantFraudDetailType: {
          isLoading: { $set: false },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case MerchantFraudType.GET_DETAIL_MERCHANT_FRAUD_FAILED:
      return update(state, {
        MerchantFraudDetailType: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
      return state;
  }
};
