import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import PublicRoute from './Components/PublicRoute';
import EmailValidate from './Views/Login/EmailValidate';
import { routePath } from 'utils/exception';
import { ToastContainer } from 'react-toastify';
import { CircularProgress } from 'Components/Loading';

const Login = lazy(() => import('./Views/Login'));
const KeycloakAuth = lazy(() => import('./Views/Login/PopupKeycloak'));
const ForgotPassword = lazy(() => import('./Views/Login/ForgotPassword'));
const ResetPassword = lazy(() => import('./Views/Login/ResetPassword'));
const DefaultLayout = lazy(() => import('./Containers/NewLayout_v2'));
const NotFound = lazy(() => import('./Containers/NotFound'));
const ConnectionError = lazy(() => import('./Containers/ConnectionError'));
const EmailSent = lazy(() => import('./Containers/Email'));
const DownloadQRIS = lazy(() => import('./Views/Merchant/QRIS/contents/parts/preview-qr'));

function App() {
  const Loading = () => <CircularProgress />;

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Switch>
          <PublicRoute name='Email Validate' path={`${routePath}email/validate`} component={EmailValidate} />
          <PublicRoute
            name='Reset Password'
            path={`${routePath}reset-password/validate`}
            component={ResetPassword}
            exact
          />
          <PublicRoute name='Forgot Password' path={`${routePath}forgot`} component={ForgotPassword} exact />
          <PublicRoute name='Login' path={`${routePath}login`} component={Login} />
          <Route exact path={`${routePath}404`} component={NotFound} />
          <Route exact path={`${routePath}email-sent`} component={EmailSent} />
          <PublicRoute path={`${routePath}authenticating`} component={KeycloakAuth} />

          <Route path={'/download-qr'} component={DownloadQRIS} />
          <Route exact path='/err/network' component={ConnectionError} />
          <Route path={routePath} component={DefaultLayout} />
        </Switch>
        <ToastContainer position='top-right' autoClose={4000} pauseOnHover />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
