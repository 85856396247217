import { AxiosError } from 'axios';

export enum AdminTaskTypes {
  CREATE_INCIDENT = 'CREATE_INCIDENT',
  EDIT_INCIDENT = 'EDIT_INCIDENT',

  GET_LIST_TASK_SUCCESS = 'GET_LIST_TASK_SUCCESS',
  GET_DETAIL_SUCCESS = 'GET_DETAIL_SUCCESS',
  GET_DETAIL_PAYOUT_SUCCESS = 'GET_DETAIL_PAYOUT_SUCCESS',

  FETCH_PENDING = 'FETCH_PENDING',
  FETCH_FAILED = 'FETCH_FAILED',
}

// ? STATE
export interface AdminTaskState {
  isLoading: boolean;
  error: AxiosError;
  ListTask: ResponseListData;
  DetailTask: ResponseDetailAdmData;
  DetailPayout: ResponseDetailAdmPayoutData;
}

// ? DATA
export type ListTaskParams = {
  page: number;
  limit: number;
  type: 1 | 2 | 3 | 4;
  no?: string;
  clasification?: string;
  reporter?: string;
  date?: string;
  date_sort?: number;
};
export type CreateIncidentData = {
  type: 4;
  notes?: string; // ? optional ?
  resolve_date?: string; // ? optional ?
  detail_data?: DetailIncidentData;
};

export type ListTaskData = {
  // main data
  sla?: number;
  sla_due?: string;
  sla_due_date?: string;
  id?: number;
  create_date?: string;
  status?: number;
  // incident
  number?: string;
  type?: string;
  clasification?: string;
  reporter?: string;
  data_centre_location?: string;
  platform?: string;
  incident_date?: string;
  // payout
  priority?: string;
  error_message?: string;
  status_payout?: status_payout;
  total_amount?: number;
  total_benef?: number;
  trans_no?: string;
  // refund
  merchant?: string;
  pc_code?: string;
  ref_no?: string;
  refund_amount?: number;
  trans_date?: string;
  update_admin_name?: string;
  update_by?: number;
  update_date?: string;
  // settle
  merchant_code?: string;
  total_records?: number;
  settle_amount?: number;
  transfer_amount?: number;
  status_settle?: settle_status;
};

export type AdmTaskFilter = {
  page: number;
  limit: number;
  date_sort?: number;
  clasification?: string;
  sla_due_date?: string;
  status?: string;
  date?: string;
} & (fRefund | fSettle | fPayout | fIncident);
type adm_adds = { no_trans?: string; merchant_code?: string; no_ref?: string; merchant?: string };
type fRefund = { type: 1; pc_code?: string } & Omit<adm_adds, 'merchant_code'>;
type fSettle = { type: 2; status_settle?: string } & Omit<adm_adds, 'no_ref'>;
type fPayout = { type: 3; total_benef?: number } & Omit<adm_adds, 'merchant_code' | 'merchant'>;
type fIncident = { type: 4; no?: string; reporter?: string };

export type ResponseListData = {
  list_task: ListTaskData[];
  total_records: number;
  total_initiate: number;
};

export type ResponseDetailAdmData = {
  create_by?: number;
  create_date: string;
  id: number;
  message_id?: number;
  resolve_date?: string;
  status?: number;
  update_by?: number;
  update_date?: string;
  notes?: string;
  update_admin_name?: string;
} & (DetailType1 | DetailType2 | DetailType3 | DetailType4);

type DetailType1 = {
  type: 1;
  detail_data: DetailDataRefund;
};
type DetailType2 = {
  type: 2;
  detail_data: DetailDataSettle;
  detail_data_after: DetailDataSettle;
};
type DetailType3 = {
  type: 3;
  detail_data: DetailDataPayout;
  detail_data_after: DetailDataPayout;
};
type DetailType4 = {
  type: 4;
  detail_data: DetailIncidentData;
};

type DetailDataRefund = {
  customer_email: string;
  customer_name: string;
  customer_phone: string;
  merchant: string;
  merchant_id: number;
  pc_code: string;
  priority: string;
  ref_no: string;
  refund_amount: number;
  response: string;
  rf_reason: string;
  rf_request_date: string;
  rf_source_id: number;
  rf_state: number;
  rf_status: number;
  sla: number;
  sla_due_date: string;
  trans_amount: number;
  trans_date: string;
  trans_no: string;
};
type DetailDataSettle = {
  ErrorMessage: string;
  ErrorResponseCode: string;
  beneficiary_result: string;
  create_by: number;
  data_result: string;
  date: string;
  detailWD: {
    current_page: number;
    list_wd: ListWd[];
    next: number;
    pages: number;
    previous: number;
    total_records: number;
  };
  fee: number;
  id: number;
  merchant_code: string;
  merchant_id: number;
  merchant_name: string;
  priority: string;
  receipt_no: string;
  schedule: string;
  sla: number;
  sla_due_date: string;
  state: number;
  stateText: string;
  status: number;
  statusText: string;
  total_amount: number;
  total_initial_amount: number;
  total_mdr_fee: number;
  total_payable_amount: number;
  total_settle_fee: number;
  total_third_party_fee: number;
  total_transfer_amount: number;
  total_trx_record: number;
  transaction_no: string;
  update_by: number;
  update_date: string;
};
type DetailDataPayout = {
  detail_payout: DetailPayoutData[];
  error_message: string;
  merchant_id: number;
  sla: number;
  sla_due_date: string;
  trans_no: string;
  total_benef: number;
  total_amount: number;
  status_payout: number;
};
type ListWd = {
  fee: number;
  fee_type: number;
  id: number;
  mdr_fee: number;
  nett_fee: number;
  third_party_fee: number;
  trx_amount: number;
  trx_amount_initial: number;
  trx_created: string;
  trx_fee: number;
  trx_no: string;
  trx_pc_code: string;
  trx_reference_no: string;
  trx_state: number;
  trx_status: number;
  trx_third_party_fee: number;
  trx_updated: string;
  wd_id: number;
};

export type ResponseDetailAdmPayoutData = {
  create_by?: number;
  create_date: string;
  detail_data: {
    detail_payout: DetailPayoutData[];
    error_message: string;
    merchant_id: number;
    sla: number;
    sla_due_date: string;
    trans_no: string;
    total_benef: number;
    total_amount: number;
    status_payout: number;
  };
  id: number;
  type: number;
  detail_data_after?: string;
  message_id?: number;
  resolve_date?: string;
  status: number;
  update_by?: number;
  update_date?: string;
};

export type DetailPayoutData = {
  status: string;
  benef_account_name: string;
  benef_account_number: string;
  benef_address?: string;
  benef_bank: string;
  benef_email: string;
};

export type EditAdmTaskData = {
  id: number; // ! required !
  status: number; // ! required !
} & (UpdateRefundData | UpdatePayoutData | CreateIncidentData | UpdateSettleData);

type formatAdminTaskUpdate = {
  notes?: string; // ? optional ?
  resolve_date?: string; // ? optional ?
};
type UpdateRefundData = {
  type: 1;
  detail_data_after?: {
    ref_no?: string;
    rf_status?: number;
    refund_charge_fee?: number;
    rf_references_id?: string;
  };
} & formatAdminTaskUpdate;

type UpdatePayoutData = {
  type: 3;
  resend: boolean;
  trans_no: string;
} & formatAdminTaskUpdate;

type UpdateSettleData = {
  type: 2;
  resend: boolean;
} & formatAdminTaskUpdate;

type DetailIncidentData = {
  number?: string;
  type?: string;
  clasification?: string;
  reporter?: string;
  data_centre_location?: string;
  platform?: string;
  incident_date?: string;
  service?: string;
  solving_engineer?: string;
  incident_information?: {
    issue: string;
    cause: string;
    impact: string;
    recovery: string;
  };
  chronology?: {
    explanation: string;
    action: string;
    mitigation: string;
    recov: string;
  };
  sla?: number;
  sla_due?: string;
};
export type settle_status = 'PROCESS' | 'FAILED' | 'SUCCESS';
export type status_payout = 150 | 200;

// ! ACTIONS
interface GetListStart {
  type: AdminTaskTypes.FETCH_PENDING;
  payload: null;
}
interface GetListSuccess {
  type: AdminTaskTypes.GET_LIST_TASK_SUCCESS;
  payload: ResponseListData;
}
interface GetDetailSuccess {
  type: AdminTaskTypes.GET_DETAIL_SUCCESS;
  payload: ResponseDetailAdmData;
}
interface GetListFailed {
  type: AdminTaskTypes.FETCH_FAILED;
  payload: { error: AxiosError };
}
interface GetDetailPaySuccess {
  type: AdminTaskTypes.GET_DETAIL_PAYOUT_SUCCESS;
  payload: ResponseDetailAdmPayoutData;
}

type AdminTaskList = GetListStart | GetListSuccess | GetDetailSuccess | GetListFailed | GetDetailPaySuccess;

export type IAdminTask = AdminTaskList;
