import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import authReducer from './authReducer';
import utilsReducer from './utilsReducer';
import reportReducer from './reportReducer';
import registeredMerchantReducer from './registeredReducer';
import settlementReducer from './settlementReducer';
import merchantPreviewReducer from './merchantPreviewReducer';
import ticketingReducer from './ticketingReducer';
import dashboardReducer from './dashboardReducer';
import adminTasksReducer from './adminTasksReducer';
import masterDataReducer from './masterDataReducer';
import notificationReducer from './notificationReducer';
import fmsReducer from './fmsReducer';
import FMSPayoutReducer from './fmsPayoutReducer';
import FMSThresholdReducer from './fmsThreshold';
import merchantFraudReducer from './merchantFraudReducer';

export const rootReducer = combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error'],
      whitelist: ['userData'],
    },
    authReducer
  ),
  utils: utilsReducer,
  report: reportReducer,
  registered: registeredMerchantReducer,
  settlement: settlementReducer,
  mercPreview: merchantPreviewReducer,
  ticketing: ticketingReducer,
  dashboard: dashboardReducer,
  adminTask: persistReducer({ key: 'DetailTask', storage }, adminTasksReducer),
  masterData: persistReducer(
    {
      key: 'masterData',
      storage,
      blacklist: [],
      whitelist: ['DataMasterData'],
    },
    masterDataReducer
  ),
  notification: notificationReducer,
  // fms: fmsReducer,
  fms: persistReducer(
    {
      key: 'fms',
      storage,
      blacklist: ['error', 'loadtotalIncatedCategory', 'loadtotalIncatedCategory', 'loadtotalIndicated'],
      whitelist: [
        'totalIndicated',
        'totalIncatedCategory',
        'trendTotalIndicated',
        'detailIndicated',
        'listMerchantSus',
        'loadtotalIndicated',
      ],
    },
    fmsReducer
  ),
  FmsPayout: FMSPayoutReducer,
  Threshold: FMSThresholdReducer,
  MrcFraud : merchantFraudReducer,
});
