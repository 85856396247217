import { Button, ButtonProps, ThemeProvider } from "@mui/material"
import { btnTheme } from "configs/theme"
import React from "react"
import { icons } from "assets"

interface propsUpload {
    onClick: () => void
}
const Component: React.FC = (props: propsUpload) => {

    const { onClick } = props

    return (
        <ThemeProvider theme={btnTheme}>
            <Button onClick={onClick} {...props} variant="outlined">
                <img src={icons.IcUpload} className="mr-2 w-4   " alt="upload" />
                <span>upload</span>
            </Button>
        </ThemeProvider>
    )
}

export default Component 