import Ind from './icons/Group 24.svg';
import Eng from './icons/Group 27.svg';
import profile from './img/profile.jpg';
import success from './icons/success-circle.png';
import reject from './icons/rejected-circle.png';
import warning from './icons/warning-circle.png';
import info from './icons/info-circle.png';
import refreshIcon from './icons/refresh.svg';
import infoError from './icons/info-error.svg';
import infoQ from './icons/info-question.svg';
import lonceng from './icons/lonceng.svg';

import EditPen from './icons/edit-pen.svg';
import EditPen2 from './icons/edit2.svg';
import EditPhoto from './icons/photo-camera.png';
import Calendar from './icons/calendar.svg';

import lightWide from './img/QoinHub-logo-sidebar.svg';
import darkWide from './img/QoinHub-dark-wide.svg';
import checkEmail from './img/email-lock.png';

import infoCircle from './icons/info-circle.png';
import successCircle from './icons/success-circle.png';
import warningCircle from './icons/warning-circle.png';
import questionCircle from './icons/question-circle.png';
import rejectCircle from './icons/rejected-circle.png';

import Info from './icons/info.svg';
import Reject from './icons/reject.svg';
import Transaction from './icons/transaction.svg';
import Approve from './icons/approve.svg';
import Default from './icons/default.svg';

import EditGreenPencil from './icons/pencil.svg';
import Eye from './icons/eye.svg';
import Trash from './icons/trash.svg';
import Checklist from './icons/checklist.svg';
import Cancel from './icons/cancel.svg';
import IcUpload from './icons/upload.svg';
import MutationDebit from './img/total-debit-blue.svg';
import MutationCredit from './img/total-credit-red.svg';

import Print from './icons/printer-green.svg';
import Send from './icons/send-white.svg';
import miniWarning from './icons/mini-warning.svg';
import downloadAddr from './icons/download-green.svg';

import qrFrame from './img/qris-frame/qris-frame.png';
import qrisLogo from './img/qris-frame/qris-logo.png';
import gpnLogo from './img/qris-frame/gpn-logo.png';

export const popupIcon = { success, warning, reject, info };
export const langIcon = { Ind, Eng };
export const icons = {
  Ind,
  Eng,
  refreshIcon,
  infoError,
  infoQ,
  EditPen,
  EditPhoto,
  lonceng,
  Calendar,
  Info,
  Reject,
  Transaction,
  Approve,
  Default,
  EditPen2,
  EditGreenPencil,
  Eye,
  Trash,
  Checklist,
  Cancel,
  IcUpload,
  miniWarning,
};
export const img = { profile };
export const logo = { lightWide, darkWide };
export const illust = { checkEmail };
export const circle = { infoCircle, successCircle, warningCircle, questionCircle, rejectCircle };
export const reportImg = { MutationCredit, MutationDebit };
export const buttonIcon = { Print, Send, downloadAddr };

export const qrisFrames = { qrFrame, qrisLogo, gpnLogo };
