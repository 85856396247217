import { AxiosError } from 'axios';
import { MccData, QrBizCriteria } from './qrisTypes';

export enum RegisteredMerchantTypes {
  GET_LIST_NEW_MERCHANT_START = 'GET_LIST_NEW_MERCHANT_START',
  GET_LIST_NEW_MERCHANT_SUCCESS = 'GET_LIST_NEW_MERCHANT_SUCCESS',
  GET_LIST_NEW_MERCHANT_FAILED = 'GET_LIST_NEW_MERCHANT_FAILED',
  GET_DETAIL_NEW_MERCHANT_START = 'GET_DETAIL_NEW_MERCHANT_START',
  GET_DETAIL_NEW_MERCHANT_SUCCESS = 'GET_DETAIL_NEW_MERCHANT_SUCCESS',
  GET_DETAIL_NEW_MERCHANT_FAILED = 'GET_DETAIL_NEW_MERCHANT_FAILED',
  GET_LIST_PROD_MERCHANT_START = 'GET_LIST_PROD_MERCHANT_START',
  GET_LIST_PROD_MERCHANT_SUCCESS = 'GET_LIST_PROD_MERCHANT_SUCCESS',
  GET_LIST_PROD_MERCHANT_FAILED = 'GET_LIST_PROD_MERCHANT_FAILED',
  GET_LIST_SANDBOX_MERCHANT_START = 'GET_LIST_SANDBOX_MERCHANT_START',
  GET_LIST_SANDBOX_MERCHANT_SUCCESS = 'GET_LIST_SANDBOX_MERCHANT_SUCCESS',
  GET_LIST_SANDBOX_MERCHANT_FAILED = 'GET_LIST_SANDBOX_MERCHANT_FAILED',
  APPROVE_SANDBOX_MERCHANT_START = 'APPROVE_SANDBOX_MERCHANT_START',
  APPROVE_SANDBOX_MERCHANT_SUCCESS = 'APPROVE_SANDBOX_MERCHANT_SUCCESS',
  APPROVE_SANDBOX_MERCHANT_FAILED = 'APPROVE_SANDBOX_MERCHANT_FAILED',
  APPROVE_PRODUCTION_MERCHANT_START = 'APPROVE_PRODUCTION_MERCHANT_START',
  APPROVE_PRODUCTION_MERCHANT_SUCCESS = 'APPROVE_PRODUCTION_MERCHANT_SUCCESS',
  APPROVE_PRODUCTION_MERCHANT_FAILED = 'APPROVE_PRODUCTION_MERCHANT_FAILED',
  COMPLETE_DOCUMENT_MERCHANT_START = 'COMPLETE_DOCUMENT_MERCHANT_START',
  COMPLETE_DOCUMENT_MERCHANT_SUCCESS = 'COMPLETE_DOCUMENT_MERCHANT_SUCCESS',
  COMPLETE_DOCUMENT_MERCHANT_FAILED = 'COMPLETE_DOCUMENT_MERCHANT_FAILED',
  EXPORT_REGISTERED_MERCHANT_START = 'EXPORT_REGISTERED_MERCHANT_START',
  EXPORT_REGISTERED_MERCHANT_SUCCESS = 'EXPORT_REGISTERED_MERCHANT_SUCCESS',
  EXPORT_REGISTERED_MERCHANT_FAILED = 'EXPORT_REGISTERED_MERCHANT_FAILED',
  GET_MERCHANT_QRIS_LOADING = 'GET_MERCHANT_QRIS_LOADING',
  GET_MERCHANT_QRIS_FAILED = 'GET_MERCHANT_QRIS_FAILED',
  GET_LIST_MERCHANT_QRIS_SUCCESS = 'GET_LIST_MERCHANT_QRIS_SUCCESS',
  GET_DETAIL_MERCHANT_QRIS_SUCCESS = 'GET_DETAIL_MERCHANT_QRIS_SUCCESS',
  UPDATE_QRIS_DATA = 'UPDATE_QRIS_DATA',
  UPDATE_QRIS_CRED = 'UPDATE_QRIS_CRED',
  GET_LIST_MCC = 'GET_LIST_MCC',
  GET_LIST_MBIZ_CRITERIA = 'GET_LIST_MBIZ_CRITERIA',
}

// State
export interface IRegisteredMerchantState {
  NewMerchant: {
    isLoading: boolean;
    data: MerchantData;
    message: string;
  };
  detailNewMerchant: {
    isLoading: boolean;
    data: DetailNewMerchantInterface;
    message: string;
  };
  ProdMerchant: {
    isLoading: boolean;
    data: MerchantData;
    message: string;
  };
  SandboxMerchant: {
    isLoading: boolean;
    data: MerchantData;
    message: string;
  };
  Export: {
    isLoading: boolean;
    message?: string;
  };
  ApproveSandbox?: ApprovalData;
  ApproveProduction?: ApprovalData;
  CompleteDocument?: ApprovalData;
  MerchantQRIS: { data: Partial<MerchantQRISState>; isLoading: boolean };
  MerchantQrDetail: Partial<DetailQRISState>;
  MasterMcc: MccData[];
  MasterBizCrit: QrBizCriteria[];
}

// Data
interface MerchantData {
  data_merchant?: merchant_detail[];
  next?: number;
  pages?: number;
  previous?: number;
  total_records?: number;
}
export interface MerchantDetailData {
  code?: string;
  date_insert?: string;
  date_register?: string;
  date_sandbox?: string;
  date_update?: string;
  id?: number;
  management_type?: string;
  name?: string;
  package_type?: number;
  parent?: string;
  state?: string;
  status?: string;
}

export interface merchant_detail {
  address?: string;
  city?: string;
  client_id?: number;
  code?: string;
  country?: string;
  date_insert?: string;
  date_update?: string;
  deleted?: number;
  id?: number;
  management_type?: string;
  name?: string;
  package_type?: number;
  parent?: string;
  province?: string;
  status?: number;
  thema_list?: string;
  thema_pic?: string;
  zip?: string;
}

export interface merchant_biz {
  akta_url?: string;
  bank?: string;
  bank_acc_name?: string;
  bank_acc_no?: string;
  bank_acct_url?: string;
  bis_category_code?: string;
  bis_type_code?: string;
  bis_url?: string;
  date_register?: string;
  finance_email?: string;
  finance_phone?: string;
  is_doc_validated?: number;
  id?: number;
  is_developer?: number;
  ktp_name?: string;
  ktp_no?: string;
  ktp_url?: string;
  merchant_id?: number;
  nda_file_url?: string;
  nda_no?: string;
  nda_date?: string;
  note?:string;
  npwp_address?: string;
  npwp_name?: string;
  npwp_number?: string;
  npwp_url?: string;
  own_address?: string;
  own_citizen?: string;
  own_phone?: string;
  own_zip?: string;
  pks_file_url?: string;
  pks_date?: string;
  pks_no?: string;
  state?: number;
  skm_url?: string;
  tech_email?: string;
  tech_phone?: string;
}

interface ApprovalData {
  isLoading: boolean;
  message: string;
}
interface merchant {
  merchant: merchant_detail;
  merchant_biz: merchant_biz;
}

export interface payments {
  chanels?: chanel[];
  id?: number;
  picture?: {
    url?: string;
  };
}

interface chanel {
  id?: number;
  name?: string;
  page_id?: number;
  picture?: {
    url?: string;
  };
}

export interface DetailNewMerchantInterface {
  email_register_account?: string;
  payments?: payments[];
  merchant?: merchant;
}

export interface CompleteDocumentForm {
  NdaDate?: Date | null;
  NdaNumber?: string;
  PksDate?: null | Date;
  PksNumber?: string;
  Note?: string;
  Validated?: number;
  MerchantId?: number;
  AktaFile?: File | null;
  SkFile?: File | null;
  BankAccFile?: File | null;
  NDA?: File | null;
  PKS?: File | null;
  MerchantCode?: number;
  BankName?: string;
  BankAccountNumber?: string;
  BankAccountName?: string;
}

export interface locationCompleteDocument {
  hash?: string;
  key?: string;
  pathname?: string;
  search?: string;
  state?: {
    MerchantCode?: number;
    merchantId?: number;
    is_validate?: number;
    status_merchant?: number;
  };
}
interface filesPdf {
  file?: null | ArrayBuffer | string;
  pageNumber?: null | number;
  page?: null | number;
}
export interface previewPDF {
  AktaPendirian?: filesPdf;
  SKPendirian?: filesPdf;
  BukuRekening?: filesPdf;
  PKSfile?: filesPdf;
  NDA?: filesPdf;
}

export type MerchantQRISState = { data: MercQRISData[]; paginate: MercQRISpaginate };
export type DetailQRISState = { detail: MqDetailInfo; merchant_qris: MqCredentialList; terminal: MqTerminalList };
export type qr_status_text = 'registered' | 'active' | 'submit' | 'inactive';
export type MercQRISData = {
  m_id: number;
  mq_id: number;
  mq_merchant_name: string;
  mq_status: number; // added on 26/03/2024 by BE
  mq_status_value: qr_status_text; // changed without notice on 26/03/2024 by BE
  mq_create_date: string;
  mq_merchant_code: string;
};
type MercQRISpaginate = {
  current_items_count: number;
  current_page_number: number;
  has_next_page: boolean;
  has_prev_page: boolean;
  next_page_number: number;
  next_page_url: string;
  offset: number;
  prev_page_number: number;
  prev_page_url: string;
  requested_page_size: number;
  total_pages_count: number;
  total_record: number;
};

export type MqDetailInfo = {
  merchant_id: number;
  type_qr: 1 | 2 | 3;
  type_qr_value: string;
  status: 0 | 1 | 2 | 3;
  status_value: string;
  status_action: string;
  jenis_usaha: string;
  business_type: number;
  business_type_value: string;
  owner_name: string;
  account_no: string;
  identity_type: number;
  identity_type_value: string;
  identity_no: string;
  npwp: string;
  phone_number: string;
  criteria: string;
  criteria_value: string;
  merchant_name: string;
  category: number;
  category_value: string;
  business_address: string;
  province: string;
  city: string;
  subdistrict: string;
  postal_code: string;
  ktp_url: string;
  sku_url: string;
  akta_url: string;
  surat_kuasa_url: string;
  mq_mcc: number;
  mq_type_tips: number; // 1 (tanpa), 2 (input user), 3 (static), 4 (percent)
  mq_tip_static: number; // nominal tips
};
export type MqCredential = {
  id: number;
  partner_id: number;
  merchant_id: number;
  qr_static: string;
  qr_static_url: string;
  status: string; // 01/04/2024 - diganti dari BE tanpa info jadi string
  dynamic_client_id: string;
  dynamic_client_secret: string;
  static_client_id: string;
  static_client_secret: string;
  partner_name: string;
  nmid: string;
  m_id: string;
  mq_merchant_id: string;
  mpan: string;
  qoin_id: string;
  static_status: string; // 01/04/2024 - diganti dari BE tanpa info jadi string
  dynamic_status: string; // 01/04/2024 - diganti dari BE tanpa info jadi string
  partner_pnns: string;
  print_date: string;
};
export type MqTerminal = {
  id: number;
  merchant_qris_id: number;
  name: string;
  phone: string;
  terminal_id: string;
  email: string;
};
type MqTerminalList = MqTerminal[];
type MqCredentialList = MqCredential[];

// Action
interface IGetNewMerchantListStart {
  type: RegisteredMerchantTypes.GET_LIST_NEW_MERCHANT_START;
  payload: null;
}
interface IGetNewMerchantListSuccess {
  type: RegisteredMerchantTypes.GET_LIST_NEW_MERCHANT_SUCCESS;
  payload: MerchantData;
}
interface IGetNewMerchantListFailed {
  type: RegisteredMerchantTypes.GET_LIST_NEW_MERCHANT_FAILED;
  payload: any;
}
interface IGetDetailNewMerchantStart {
  type: RegisteredMerchantTypes.GET_DETAIL_NEW_MERCHANT_START;
  payload: null;
}
interface IGetDetailNewMerchantSuccess {
  type: RegisteredMerchantTypes.GET_DETAIL_NEW_MERCHANT_SUCCESS;
  payload: DetailNewMerchantInterface;
}
interface IGetDetailNewMerchantFailed {
  type: RegisteredMerchantTypes.GET_DETAIL_NEW_MERCHANT_FAILED;
  payload: any;
}
interface IGetProdMerchantStart {
  type: RegisteredMerchantTypes.GET_LIST_PROD_MERCHANT_START;
  payload: null;
}
interface IGetProdMerchantSuccess {
  type: RegisteredMerchantTypes.GET_LIST_PROD_MERCHANT_SUCCESS;
  payload: MerchantData;
}
interface IGetProdMerchantFailed {
  type: RegisteredMerchantTypes.GET_LIST_PROD_MERCHANT_FAILED;
  payload: any;
}
interface IGetSandboxMerchantStart {
  type: RegisteredMerchantTypes.GET_LIST_SANDBOX_MERCHANT_START;
  payload: null;
}
interface IGetSandboxMerchantSuccess {
  type: RegisteredMerchantTypes.GET_LIST_SANDBOX_MERCHANT_SUCCESS;
  payload: MerchantData;
}
interface IGetSandboxMerchantFailed {
  type: RegisteredMerchantTypes.GET_LIST_SANDBOX_MERCHANT_FAILED;
  payload: any;
}

interface IAppoveProdMerchantStart {
  type: RegisteredMerchantTypes.APPROVE_PRODUCTION_MERCHANT_START;
  payload: null;
}
interface IAppoveProdMerchantSuccess {
  type: RegisteredMerchantTypes.APPROVE_PRODUCTION_MERCHANT_SUCCESS;
  payload: any; // sementara
}
interface IAppoveProdMerchantFailed {
  type: RegisteredMerchantTypes;
  payload: any;
}

interface IApproveSandboxStart {
  type: RegisteredMerchantTypes.APPROVE_SANDBOX_MERCHANT_START;
  payload: null;
}
interface IApproveSandboxSuccess {
  type: RegisteredMerchantTypes.APPROVE_SANDBOX_MERCHANT_SUCCESS;
  payload: any; // sementara
}
interface IApproveSandboxFailed {
  type: RegisteredMerchantTypes.APPROVE_SANDBOX_MERCHANT_FAILED;
  payload: any;
}

interface ICompleteMerchantStart {
  type: RegisteredMerchantTypes.COMPLETE_DOCUMENT_MERCHANT_SUCCESS;
  payload: null;
}
interface ICompleteMerchantSuccess {
  type: RegisteredMerchantTypes.COMPLETE_DOCUMENT_MERCHANT_SUCCESS;
  payload: any; // sementara
}
interface ICompleteMerchantFailed {
  type: RegisteredMerchantTypes.COMPLETE_DOCUMENT_MERCHANT_SUCCESS;
  payload: { error: AxiosError };
}

interface IMercQRISStart {
  type: RegisteredMerchantTypes.GET_MERCHANT_QRIS_LOADING;
  payload: null;
}
interface IMercQRISSuccess {
  type: RegisteredMerchantTypes.GET_LIST_MERCHANT_QRIS_SUCCESS;
  // payload: { data: MercQRISData[]; paginate: MercQRISpaginate };
  payload: MerchantQRISState;
}
interface IMercQRISFailed {
  type: RegisteredMerchantTypes.GET_MERCHANT_QRIS_FAILED;
  payload: string;
}
interface IDataQRISupdate {
  type: RegisteredMerchantTypes.UPDATE_QRIS_DATA;
  payload: unknown;
}

interface IUpdateCredQR {
  type: RegisteredMerchantTypes.UPDATE_QRIS_CRED;
  payload: MqCredentialList;
}

type INewMerchant = IGetNewMerchantListFailed | IGetNewMerchantListStart | IGetNewMerchantListSuccess;

type IDetailNewMerchant = IGetDetailNewMerchantFailed | IGetDetailNewMerchantStart | IGetDetailNewMerchantSuccess;

type IProdMerchant = IGetProdMerchantFailed | IGetProdMerchantStart | IGetProdMerchantSuccess;

type ISandboxMerchant = IGetSandboxMerchantFailed | IGetSandboxMerchantStart | IGetSandboxMerchantSuccess;

type IApproveProdMerchant = IAppoveProdMerchantFailed | IAppoveProdMerchantStart | IAppoveProdMerchantSuccess;

type IApproveSandBoxMerchant = IApproveSandboxStart | IApproveSandboxSuccess | IApproveSandboxFailed;

type ICompleteMerchant = ICompleteMerchantFailed | ICompleteMerchantStart | ICompleteMerchantSuccess;

type IMerchantQRIS = IMercQRISStart | IMercQRISFailed | IMercQRISSuccess | IDataQRISupdate | IUpdateCredQR;

export type IRegistered =
  | INewMerchant
  | IDetailNewMerchant
  | IProdMerchant
  | ISandboxMerchant
  | IApproveProdMerchant
  | IApproveSandBoxMerchant
  | ICompleteMerchant
  | IMerchantQRIS;
